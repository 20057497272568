/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10%;
    padding-right: 10%;
}

.title-form {
    text-align: center;
    margin: 20px;
    background: rgb(69, 111, 226);
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 40px;
    margin-left: 25%;
    margin-right: 25%;
    color: white;
}

.subtitle-form {
    text-align: center;
    // margin: 20px;
    /* background: rgb(69, 111, 226);
    border-radius: 20px; */
    padding: 10px;
    color: rgb(69, 111, 226);
}

.title-info {
    text-align: center;
    margin: 20px;
    background: rgb(229, 226, 226);
    border-radius: 20px;
    padding: 10px;
}

ion-split-pane{ 
    --border: none !important;
    ion-menu{
     --background: var(--ion-color-primary-shade) !important; 
        ion-content{            
            --background: var(--ion-color-primary) !important; 
            ion-list-header{ 
                --background: var(--ion-color-primary) !important;
                --color: #FFFFFF !important;   
            }
            ion-item{
                --background: var(--ion-color-primary) !important;
                 --color: #FFFFFF !important;
                 ion-icon{ padding-right: 10px !important; }
            }
        }
    }
}

ion-content{
    --background: aliceblue;
}

ion-avatar { 
    width: 45px;  
    height: 45px;  
    padding: 5px;
}

.button-web{
    margin-top: 20px;
}

.bg-white{
    background-color: #FFFFFF;
}

.centro{
    margin-top: 40px;
}
